// 业绩取值方案
import * as API from '@/api/index'

export default {
    //结算周期设置
    SalarySheetCreateSettlementInterval: params => {
        return API.POST('api/salarySheet/createSettlementInterval', params)
    },
    //结算周期查询
    SalarySheetAllSettlementInterval: params => {
        return API.POST('api/salarySheet/allSettlementInterval', params)
    },
    //工资条列表
    SalarySheetAll: params => {
        return API.POST('api/salarySheet/all', params)
    },
    //个人业绩提成
    SalarySheetEmployeeCommission: params => {
        return API.POST('api/salarySheet/employeeCommission', params)
    },
    //门店业绩提成
    SalarySheetEntityCommission: params => {
        return API.POST('api/salarySheet/entityCommission', params)
    },
     //销售业绩提成
     SalarySheetSaleCommission: params => {
        return API.POST('api/salarySheet/saleCommission', params)
    },
     //消耗业绩提成
     SalarySheetTreatCommission: params => {
        return API.POST('api/salarySheet/treatCommission', params)
    },
    //结算
    SalarySheetSettlement: params => {
        return API.POST('api/salarySheet/settlement', params)
    },
    // 导出
    exportSalarySheetExce: params => {
        return API.exportExcel('api/salarySheet/excel', params)
      },
}